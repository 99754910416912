import React, { useRef, useEffect, useState } from 'react';
import { Row, Col } from "react-bootstrap";



import Gal from "./Why";

import met from "../../Assets/met.svg";
const Section = () => {

  
  return (
    <Row className="section-images container  introduction" >
     
      <Col md={8}  className="ord2">
      
          <Gal />
        
      </Col>
      <Col md={4} className="d-flex flex-column justify-content-center ord1">
       
      <div className="newtitle">
    <h5>INTRODUCING</h5>
    <h2>DeDondi</h2>
    <img src={met} alt="Met SVG" className="metreg" />


</div>

     
        
        <p className="home-about-body white-color">
        Every DeDondi device is an ode to precision
engineering. Inspired by timeless designs, crafted with
the finest materials, and engineered for those who
demand the best. From the sleek curves to the detailed
finish, this is more than a device. It's a Statement.
        </p>
      </Col>
    </Row>
  );
};

export default Section;
