import React from 'react';
import { Row, Col, Button } from "react-bootstrap";
import { FaAndroid } from "react-icons/fa"; // Importing the Android icon
import image from "../../Assets/banner2.png";

const Section = () => {
  return (
    <div className="elite mg-between">
      <Row className="section-images container" style={{ left: "0", right: "0", margin: "auto" }}>
        <Col md={6} className="ord2 d-flex flex-column justify-content-center on">
          <img 
            src={image}
            alt="image elite"
            style={{ width: "100%", height: "auto" }}
            data-aos="fade-up"
          />
        </Col>
        <Col md={6} className="d-flex flex-column justify-content-center ord1 pd-text-left">
          <div className="newtitle">
            <h2 className="white-color">Dedondi App</h2>
          </div>
          <p className="home-about-body white-color">
            Be among the first to explore the initial version of the Dedondi app. Connect your classic watch to our upcoming smart buckle and experience the beginning of a smarter timekeeping journey. Install now!
          </p>
          <div className="text-center mt-3">
            <Button 
              variant="primary" 
              href="/dedondi.apk" // Public directory path
              download
              className="d-flex align-items-center justify-content-center btn-install"
            >
              <FaAndroid className="me-2" /> Install Now
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Section;
