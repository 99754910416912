import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const Wait = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [display, setdiplay] = useState('');
    const [btn, setbtn] = useState('Join waitlist');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setbtn('Joining...');
        setError('');
        setSuccess('');

        try {
            const response = await axios.post('https://back-dedondi.vercel.app/wait', { email });
           // setSuccess(response.data);
            setbtn('congrats!');
           // setdiplay('none');
        } catch (err) {
           // setError('Error saving contact information');
            console.error(err);
            setbtn('Join waitlist');
           
        } finally {
            setLoading(false);
            // setbtn('Join waitlist');
            
        }
    };

    return (
        <div className="container mt-2">
           
            <form onSubmit={handleSubmit} className="d-flex align-items-center form-wait">
                <div className="input-group  ">
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="test@gmail.com"
                       
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                >
                    {btn}
                </button>
            </form>
          
        </div>
    );
};

export default Wait;
