import React, { useRef, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import vid from "../../Assets/video2.mp4";
import { useInView } from "react-intersection-observer";

import met from "../../Assets/met.svg";
const Section = () => {
  const videoRef = useRef(null);
  const [ref, inView] = useInView({
    threshold: 0.5, // Trigger when 50% of the video is in view
  });

  useEffect(() => {
    let timeout;
    if (videoRef.current) {
      if (inView) {
        timeout = setTimeout(() => {
          videoRef.current.play();
        }, 100); // Add a 100ms delay before playing
      } else {
        timeout = setTimeout(() => {
          videoRef.current.pause();
        }, 100); // Add a 100ms delay before pausing
      }
    }
    return () => clearTimeout(timeout); // Clear timeout if the effect cleans up
  }, [inView]);

  return (
    <div className="excude mg-between" id="excude" ref={ref}>
      
      <Row className="section-images container " style={{ left: "0", right: "0", margin: "auto" }}>
        <Col md={6} className="d-flex flex-column justify-content-center ord1 pd-text-right" >
          <div className="newtitle">
            <h5>Exude  </h5>
            <h2>Sophistication</h2>
            <img src={met} alt="Met SVG" className="metreg" />

          </div>
          <p className="home-about-body white-color">
          Elevate your watch with Dedondi’s innovative
connected buckle. Designed to enhance your
favorite timepiece, it seamlessly merges the
timeless elegance of traditional watchmaking with
the cutting-edge sophistication of modern
technology.
          </p>
         
        </Col>
        <Col md={6} className="ord2 d-flex flex-column justify-content-center on" >
          <div className="video-wrapper " data-aos="zoom-in">
            <video autoPlay loop muted playsInline className="video" width="100%">
              <source src={vid} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Section;
